<template>
  <div>
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col lg="4">
          <b-card no-body class="mb-4 h-lg-100 card-scroll">
            <b-card-header>
              <h5 class="fw-light mb-0">Jouw berichten</h5>
            </b-card-header>
            <b-card-body class="pt-0 pb-0">
              <SupportTicketsMenu />
            </b-card-body>
            <b-card-footer>
              <b-button to="/manage/support/new" variant="primary" block>
                Ticket openen
              </b-button>
            </b-card-footer>
          </b-card>

        </b-col>
        <b-col lg="8">
          <b-row>
            <b-col lg="6">
              <b-card no-body class="mb-4">
                  <b-card-body class="text-md-center pt-md-4 mb-md-4">
                      <b-row>
                          <b-col cols="4" md="12" align-self="center" class="text-center">
                              <font-awesome-icon icon="fa-solid fa-cog" size="3x" class="package-icon text-primary mb-3" />
                          </b-col>
                          <b-col cols="8" md="12" align-self="center">
                              <h3>Technische vraag</h3>
                              <p class="text-muted">Onze medewerkers staan voor u klaar, voor</p>
                              <b-button to="/manage/support/new#technical" variant="primary">
                                Ticket openen
                                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                              </b-button>
                          </b-col>
                      </b-row>
                  </b-card-body>
              </b-card>
            </b-col>
            <b-col lg="6">
              <b-card no-body class="mb-4">
                  <b-card-body class="text-md-center pt-md-4 mb-md-4">
                      <b-row>
                          <b-col cols="4" md="12" align-self="center" class="text-center">
                              <font-awesome-icon icon="fa-solid fa-file-invoice" size="3x" class="package-icon text-primary mb-3" />
                          </b-col>
                          <b-col cols="8" md="12" align-self="center">
                              <h3>Administratieve vraag</h3>
                              <p class="text-muted">Onze medewerkers staan voor u klaar, voor</p>
                              <b-button to="/manage/support/new#finance" variant="primary">
                                  Ticket openen
                                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                              </b-button>
                          </b-col>
                      </b-row>
                  </b-card-body>
              </b-card>
            </b-col>
            <b-col lg="12">
              <b-card no-body class="mb-4">
                  <b-card-body class="text-md-center pt-md-4 mb-md-4">
                      <b-row>
                          <b-col cols="4" md="12" align-self="center" class="text-center">
                              <font-awesome-icon icon="fa-solid fa-file-invoice" size="3x" class="package-icon text-primary mb-3" />
                          </b-col>
                          <b-col cols="8" md="12" align-self="center">
                              <h3>Algemene vraag</h3>
                              <p class="text-muted">Onze medewerkers staan voor u klaar, voor</p>
                              <b-button to="/manage/support/new#general" variant="primary">
                                  Ticket openen
                                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                              </b-button>
                          </b-col>
                      </b-row>
                  </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import SupportTicketsMenu from '@/components/SupportTicketsMenu'
  
    export default {
        metaInfo: {
            title: 'Support',
        },
        components: {
            SupportTicketsMenu,
        },
        data() {
            return {}
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>
  
<style scoped>
  .support-item{
    border-bottom:1px solid #eee;
  }
</style>